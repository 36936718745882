import { getCatalogBasePath, getSearchPath } from '@mono/util-url';
import { useRouter } from 'next/router';
import React from 'react';

import { WhiteTextAnchor } from '../styles';

type ResultsCatalogLinkProps = {
  resultsExist: boolean;
  query: string;
  fromPrevSearch?: string;
};

export const ResultsCatalogLink: React.FC<ResultsCatalogLinkProps> = ({
  resultsExist,
  query,
  fromPrevSearch,
}) => {
  const router = useRouter();

  const navigateToSearch = (query: string, fromPrevSearch?: string) => {
    return router.push(getSearchPath(query, fromPrevSearch));
  };

  const conditionalProps = resultsExist
    ? {
        onClick: () => navigateToSearch(query, fromPrevSearch),
        children: 'View all results',
      }
    : {
        href: getCatalogBasePath(),
        children: 'Go to the catalog',
      };
  return <WhiteTextAnchor variant="standard" {...conditionalProps} />;
};

import {
  Box,
  ContentContainer,
  FlexBox,
  GridBox,
  Input,
  Text,
} from '@codecademy/gamut';
import {
  MiniArrowRightIcon,
  SearchIcon,
  SupportIcon,
} from '@codecademy/gamut-icons';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import {
  HomepageSearchResults,
  LoadingCurriculumCard,
  searchWorker,
} from '@mono/brand';
import { CurriculumCardData } from '@mono/data-curriculum-cards';
import { getWelcomeOnboarding } from '@mono/util-url';
import { useEffect, useRef, useState } from 'react';

import { convertRawHomepageSearchResults } from '../../helpers/convertRawHomepageSearchResults';
import { trackingPageName } from '../../tracking';
import { SimpleCurriculumCard } from '../SimpleCurriculumCard';
import { WhiteTextAnchor } from '../styles';
import { ResultsCatalogLink } from './ResultsCatalogLink';

type SearchIdType = HomepageSearchResults['searchId'];

const SearchInput = styled(Input)(
  css({
    fontFamily: 'accent',
    fontSize: { _: 16, sm: 22 },
    borderBottom: 1,
    borderColor: 'border-tertiary',
    borderRadiusTop: 'xl',
    borderRadiusBottom: 'none',
    bg: 'white-100',
    '&::placeholder': {
      textColor: theme.colors['text-secondary'] as never,
    },
    p: { _: 16, xs: 32 },
    pr: 40,
  })
);

const QuizContainer = styled(FlexBox)`
  background-color: ${theme.colors['navy-800']};
  border: 1px solid ${theme.colors['border-tertiary']};
  border-top: none;
  border-radius: 0 0 ${theme.borderRadii.xl} ${theme.borderRadii.xl};
  color: ${theme.colors['text-secondary']};
`;

export const SearchWithFeaturedContainers: React.FC<{
  featuredContainers: CurriculumCardData[];
}> = ({ featuredContainers }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [queriedContainers, setQueriedContainers] = useState<
    CurriculumCardData[] | null
  >(null);
  const [searchId, setSearchId] = useState<SearchIdType | undefined>();
  const searchResultRef = useRef<HTMLSpanElement>(null);

  const containersToShow =
    queriedContainers && queriedContainers.length > 0
      ? queriedContainers
      : featuredContainers;
  const searchQueryExists = searchQuery.trim().length > 0;
  const showLoadingState = queriedContainers === null && searchQueryExists;
  const resultsExist =
    queriedContainers !== null && queriedContainers.length > 0;
  const showNoResults =
    queriedContainers && queriedContainers.length === 0 && searchQueryExists;

  useEffect(() => {
    searchWorker.init();
  }, []);

  useEffect(() => {
    if (!searchQueryExists) {
      return;
    }

    setQueriedContainers(null);

    let abort = false;
    const resultsPromise = searchWorker.homepageSearch(searchQuery);
    const delay = new Promise((resolve) => setTimeout(resolve, 300));

    Promise.all([resultsPromise, delay]).then(([results]) => {
      if (abort) {
        return;
      }
      setSearchId(results.searchId);
      const formattedResults = convertRawHomepageSearchResults(results.top);
      setQueriedContainers(formattedResults);
    });

    return () => {
      abort = true;
    };
  }, [searchQuery, searchQueryExists]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && searchResultRef.current) {
      searchResultRef.current.focus();
    }
  };

  return (
    <ContentContainer py={64}>
      <Box px={{ _: 12, sm: 48 }}>
        <Text
          as="h2"
          fontFamily="accent"
          variant="title-lg"
          fontWeight="normal"
        >
          Explore your interests
        </Text>

        <FlexBox
          role="search"
          position="relative"
          mt={40}
          mb={showNoResults ? 16 : 48}
          flexDirection="column"
          width="100%"
          overflow="hidden"
        >
          <SearchInput
            placeholder="What do you want to learn?"
            type="search"
            width="100%"
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            aria-describedby="screenreader-instructions"
          />
          <Box
            position="absolute"
            right={{ _: 8, xs: 32 }}
            top={{ _: 16, xs: 35 }}
          >
            <SearchIcon
              color={searchQueryExists ? 'white' : 'text-secondary'}
              size="24"
            />
          </Box>
          <Text id="screenreader-instructions" screenreader>
            Results will be updated as you type
          </Text>
          <QuizContainer
            flexDirection={{ _: 'column', md: 'row' }}
            alignItems={{ _: 'flex-start', md: 'center' }}
            justifyContent="space-between"
            gap={{ _: 8, md: 0 }}
            py={8}
            px={{ _: 16, xs: 32 }}
          >
            <FlexBox alignItems="flex-start" gap={16}>
              <SupportIcon size={24} />
              <Text variant="p-large" display="inline">
                Not sure what you want to learn or where to start?
              </Text>
            </FlexBox>
            <WhiteTextAnchor
              href={getWelcomeOnboarding()}
              variant="interface"
              icon={MiniArrowRightIcon}
              iconPosition="right"
              ml={{ _: 32, xs: 40, md: 0 }}
            >
              Take the quiz
            </WhiteTextAnchor>
          </QuizContainer>
        </FlexBox>
        {showNoResults && (
          <FlexBox justifyContent="center" pb={48}>
            <Text color="feedback-error" variant="p-base">
              No courses matched your search. Try something else.
            </Text>
          </FlexBox>
        )}
        <FlexBox alignItems="center" justifyContent="space-between" mb={24}>
          <Text
            as="h3"
            fontFamily="accent"
            variant="title-md"
            fontWeight={400}
            color="white"
            tabIndex={-1}
            ref={searchResultRef}
          >
            {`Top ${resultsExist ? 'results' : 'courses'}`}
          </Text>
          <Box display={{ _: 'none', sm: 'inline' }}>
            <ResultsCatalogLink
              resultsExist={resultsExist}
              query={searchQuery}
              fromPrevSearch={searchId}
            />
          </Box>
        </FlexBox>
        <GridBox
          m={0}
          p={0}
          as="ul"
          listStyle="none"
          gridTemplateColumns={{ sm: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
          gap={24}
        >
          <Text screenreader aria-live="polite" aria-atomic="true">
            {showNoResults ? 'No courses matched your search.' : ''}
          </Text>
          <>
            {showLoadingState
              ? Array.from({ length: 4 }).map((_, index) => (
                  <FlexBox
                    // eslint-disable-next-line react/no-array-index-key
                    key={`loading-${index}`}
                    width="auto"
                    height="100%"
                    minHeight={302}
                    as="li"
                    justifyContent="center"
                    alignItems="center"
                    data-testid="loading-curriculum-card"
                  >
                    <LoadingCurriculumCard variant="navy" />
                  </FlexBox>
                ))
              : containersToShow.map((content) => (
                  <FlexBox
                    key={content.id}
                    width="auto"
                    height="100%"
                    minHeight={302}
                    as="li"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SimpleCurriculumCard
                      headingLevel="h4"
                      content={content}
                      // TODO: add tracking data
                      trackingData={{
                        page_name: trackingPageName,
                      }}
                    />
                  </FlexBox>
                ))}
          </>
        </GridBox>
        <FlexBox
          display={{ _: 'flex', sm: 'none' }}
          width="100%"
          justifyContent="center"
          mt={32}
        >
          <ResultsCatalogLink
            resultsExist={resultsExist}
            query={searchQuery}
            fromPrevSearch={searchId}
          />
        </FlexBox>
      </Box>
    </ContentContainer>
  );
};
